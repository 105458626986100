import {ControllerProps} from '../../types/app.types';
import {BaseController} from '@wix/wixstores-client-storefront-sdk';
import {SPECS} from './constants';
import {ControllerFlowAPI, ControllerParams} from '@wix/yoshi-flow-editor';
import {CheckoutStore} from '../../domain/stores/CheckoutStore';
import {CheckoutSettingsStore} from '../../domain/stores/CheckoutSettingsStore';
import {CheckoutService} from '../../domain/services/CheckoutService';
import {CheckoutSettingsService} from '../../domain/services/CheckoutSettingsService';
import {NavigationService} from '../../domain/services/NavigationService';
import {NavigationStore} from '../../domain/stores/NavigationStore';
import {DeliveryMethodStore} from '../../domain/stores/DeliveryMethodStore';
import {BIService} from '../../domain/services/BIService';
import {SlotsStore} from '../../domain/stores/SlotsStore';
import {CheckboxesService} from '../../domain/services/CheckboxesService';
import {CheckboxesStore} from '../../domain/stores/CheckboxesStore';
import {ExpressCheckoutStore} from '../../domain/stores/ExpressCheckoutStore';
import {PaymentStore} from '../../domain/stores/PaymentStore';
import {FormsStore} from '../../domain/stores/FormsStore';
import {StepsManagerService} from '../../domain/services/StepsManagerService';
import {StepsManagerStore} from '../../domain/stores/StepsManagerStore';
import {withResultObserver} from '@wix/function-result-observation';
import {MemberStore} from '../../domain/stores/MemberStore';
import {MemberService} from '../../domain/services/MemberService';
import {PaymentService} from '../../domain/services/PaymentService';
import {FormsService} from '../../domain/services/FormsService';

export class CheckoutController extends BaseController {
  protected checkoutStore!: CheckoutStore;
  protected deliveryMethodStore!: DeliveryMethodStore;
  protected checkoutSettingsStore!: CheckoutSettingsStore;
  protected navigationStore!: NavigationStore;
  protected slotsStore!: SlotsStore;
  protected checkboxesStore!: CheckboxesStore;
  protected expressCheckoutStore!: ExpressCheckoutStore;
  protected paymentStore!: PaymentStore;
  protected stepsManagerStore!: StepsManagerStore;
  protected formsStore!: FormsStore;
  protected memberStore!: MemberStore;
  protected translationPromise!: Promise<Record<string, string>>;

  private initServiceFunctions!: (() => void)[];

  constructor(controllerParams: ControllerParams) {
    super(controllerParams);
    this.setStores(controllerParams);
    this.loadLocaleDataSetTranslations(controllerParams);

    if (!this.siteStore.experiments.enabled(SPECS.AllowCheckoutOOIForAnyFlow)) {
      this.navigationStore.navigateToOldCheckoutForUnsupportedFlow();
    }
  }

  private loadLocaleDataSetTranslations(controllerParams: ControllerParams) {
    this.translationPromise = this.fetchLocaleDataSetTranslations(controllerParams);
  }

  private async fetchLocaleDataSetTranslations(controllerParams: ControllerParams) {
    const {language} = controllerParams.flowAPI.translations.config;
    let translations: Record<string, string>;
    try {
      translations = await import(`@wix/locale-dataset-data/resources/translations/messages_${language}.json`);
    } catch (e) {
      (this.flowAPI as ControllerFlowAPI).errorMonitor.captureException(e as Error);
      translations = (await import(
        `@wix/locale-dataset-data/resources/translations/messages_en.json`
      )) as unknown as Record<string, string>;
    }
    return {...controllerParams.flowAPI.translations.all, ...translations};
  }

  private async setLocaleDatasetTranslations() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps({_translations: await this.translationPromise});
  }

  private setStores({flowAPI, controllerConfig}: ControllerParams) {
    const siteStore = this.siteStore;
    const navigationService = new NavigationService({siteStore, controllerConfig, flowAPI});
    const currency = navigationService.currency;
    const isSSR = controllerConfig.wixCodeApi.window.rendering.env === 'backend';
    const checkoutSettingsService = new CheckoutSettingsService({controllerConfig, siteStore});
    const checkboxesService = new CheckboxesService();
    const memberService = new MemberService({flowAPI, siteStore, currency});
    const biService = new BIService({
      siteStore,
      navigationService,
      checkoutSettingsService,
      memberService,
    });
    const stepsManagerService = new StepsManagerService({siteStore, biService});
    const paymentService = new PaymentService();
    const formsService = new FormsService();
    const checkoutService = new CheckoutService({
      flowAPI,
      siteStore,
      biService,
      navigationService,
      currency,
    });

    const updateComponent = this.updateComponent.bind(this);
    const onLoadFailure = this.onLoadFailure.bind(this);
    const observe = withResultObserver(controllerConfig.setProps);

    this.checkoutStore = new CheckoutStore({
      flowAPI,
      siteStore,
      checkoutService,
      updateComponent,
      checkoutSettingsService,
      navigationService,
      biService,
      stepsManagerService,
      memberService,
      observe,
    });

    this.deliveryMethodStore = new DeliveryMethodStore({
      flowAPI,
      siteStore,
      checkoutService,
      biService,
      updateComponent,
      navigationService,
    });

    this.checkoutSettingsStore = new CheckoutSettingsStore({
      siteStore,
      checkoutSettingsService,
      checkoutService,
    });

    this.navigationStore = new NavigationStore({
      navigationService,
      checkoutService,
      memberService,
      siteStore,
      biService,
      controllerConfig,
      updateComponent,
      isSSR,
    });

    this.slotsStore = new SlotsStore({
      controllerConfig,
      siteStore,
      checkoutService,
      updateComponent,
      navigationService,
      stepsManagerService,
    });

    this.checkboxesStore = new CheckboxesStore({
      checkoutService,
      checkoutSettingsService,
      checkboxesService,
      updateComponent,
    });

    this.stepsManagerStore = new StepsManagerStore({
      stepsManagerService,
      biService,
      checkoutService,
      checkoutSettingsService,
      memberService,
      siteStore,
      updateComponent,
      formsService,
      navigationService,
      isSSR,
    });

    this.expressCheckoutStore = new ExpressCheckoutStore({
      controllerConfig,
      navigationService,
      checkoutService,
      siteStore,
      flowAPI,
      biService,
    });

    this.paymentStore = new PaymentStore({
      flowAPI,
      checkoutService,
      checkoutSettingsService,
      navigationService,
      paymentService,
      memberService,
      observe,
      updateComponent,
    });

    this.formsStore = new FormsStore({
      flowAPI,
      navigationService,
      checkoutService,
      checkoutSettingsService,
      paymentService,
      biService,
      observe,
      formsService,
      updateFormsLoaded: () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.setProps({
          formsStore: this.formsStore.toProps(),
        });
      },
      onLoadFailure,
    });

    this.memberStore = new MemberStore({
      flowAPI,
      memberService,
      navigationService,
      checkoutService,
      updateComponent,
      biService,
    });

    this.initServiceFunctions = [
      () => checkoutService.init(),
      () => checkoutSettingsService.init(),
      () => navigationService.load(),
    ];
  }

  private readonly updateComponent = () => {
    const props: ControllerProps = {
      isLoading: false,
      checkoutStore: this.checkoutStore.toProps(),
      deliveryMethodStore: this.deliveryMethodStore.toProps(),
      checkoutSettingsStore: this.checkoutSettingsStore.toProps(),
      navigationStore: this.navigationStore.toProps(),
      slotsStore: this.slotsStore.toProps(),
      checkboxesStore: this.checkboxesStore.toProps(),
      stepsManagerStore: this.stepsManagerStore.toProps(),
      paymentStore: this.paymentStore.toProps(),
      formsStore: this.formsStore.toProps(),
      memberStore: this.memberStore.toProps(),
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps(props);
  };

  public readonly load = async (): Promise<void> => {
    void this.setLocaleDatasetTranslations();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps({isLoading: true, fitToContentHeight: true});
    try {
      await Promise.all(this.initServiceFunctions.map((fn) => fn()));
      if (!this.siteStore.experiments.enabled(SPECS.AllowCheckoutOOIForAnyFlow)) {
        this.navigationStore.navigateToOldCheckoutForUnsupportedFlow();
        this.navigationStore.navigateToOldCheckoutForUnsupportedItems();
      }
      await this.stepsManagerStore.initStepsManagerService();
      this.checkoutStore.sendCheckoutPageLoadBIEvent();
    } catch (e) {
      (this.flowAPI as ControllerFlowAPI).errorMonitor.captureException(e as Error);
      await this.onLoadFailure();
      return;
    }
    this.updateComponent();
  };

  private readonly onLoadFailure = async () => {
    if (this.siteStore.experiments.enabled(SPECS.DontRedirectToSiteOnFailedFetch)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.setProps({failedToFetch: true});
      return;
    }
    await this.navigationStore.onLoadFailure();
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  public readonly init = async (): Promise<void> => {
    const promise = this.load();
    /* istanbul ignore next */
    if (!this.siteStore.experiments.enabled(SPECS.ShowLoaderWhileLoadingNewCheckout)) {
      return promise;
    }
  };

  public getFreeTexts(): string[] {
    return [];
  }

  /* istanbul ignore next */
  public exports() {
    return {};
  }

  /* istanbul ignore next */

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onStyleUpdate(_styleParams: /* eslint-disable @typescript-eslint/ban-types */ {}) {}
}
